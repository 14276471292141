import React,{useState} from 'react'
import axios from 'axios'
import './Login.css'

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message,setMessage]=useState('');
    const handleSubmit = async(event) => {
        event.preventDefault();
        try {
            const url=`${process.env.REACT_APP_BASE_URL}/admin-login`;
            const {data}=await axios.post(url,{username,password});
            console.log(data);
            localStorage.setItem('token',data.data);
            window.location=`${process.env.REACT_APP_BASE_URL_FRONTEND}/CAP-registration-details`;
            setMessage(data.message);
            setUsername('');
            setPassword('');
          } catch (error) {
            setMessage(error.response.data.message);
          }
      };
  return (
    <div className='body1'>
      <div class="background">
        <div class="shape"></div>
        <div class="shape"></div>
    </div>
    <form onSubmit={handleSubmit}>
        <h3>Login</h3>

        <label for="username">Username</label>
        <input type="text" placeholder="Username" id="username" name="username" value={username} onChange={(event) => setUsername(event.target.value)}/>

        <label for="password">Password</label>
        <input type="password" placeholder="Password" id="password" name="password" value={password} onChange={(event) => setPassword(event.target.value)}/>
        <button>Log In</button>
        {message && <h3>{message}</h3>}
    </form>
    </div>
  )
}

export default Login
