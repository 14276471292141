import './App.css';
import {BrowserRouter,Route,Routes,Navigate} from 'react-router-dom';
import RegistrationDetails from './Components/CAPRegistrations/CAPRegistrations';
import Login from './Components/Login/Login';

function App() {
  const token=localStorage.getItem('token');
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/CAP-registration-details" element={token?<RegistrationDetails/>:<Navigate to="/"/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
