import React,{useEffect, useState} from 'react'
import './CAPRegistrations.css';
import axios from 'axios';
const RegistrationDetails = () => {
  const [userdetails,setUserdetails]=useState([]);
  const fetchData=()=>{
    const url=`${process.env.REACT_APP_BASE_URL}/CAP-registration-details`;
    axios.get(url).then((response)=>{
      setUserdetails(response.data);
      console.log(userdetails);
    }).catch(error=>{
      console.log(error);
    })
  }
  useEffect(()=>{
    fetchData();
  },[userdetails]);

  const handleLogout=()=>{
    localStorage.removeItem('token');
    window.location.reload();
  }
  return (
    <div className='body'>
      <h1 className='megalith'><span style={{"color":"#881616"}}>MEGA</span>LITH</h1>
      <button type="button" className="" style={{"backgroundColor":"red",width:"100px",height:"50px"}} onClick={handleLogout}>Logout</button>
      
      <h2 className='heading'>Campus Ambassador Program Registrations</h2>
      <table >
          <tr>
            <th>Megalith ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile Number </th>
            <th>College</th>
            <th>Country</th>
            <th>State</th>
            <th>City</th>
            <th>Gender</th>
            <th>YearofStudy</th>
          </tr>
          {
          userdetails.map((user) => {
            return(
            <tr>
              <td>{user.MegalithID}</td>
              <td>{user.Name}</td>
              <td>{user.Email}</td>
              <td>{user.MobNumber} </td>
              <td>{user.College}</td>
              <td>{user.Country}</td>
              <td>{user.State}</td>
              <td>{user.City}</td>
              <td>{user.Gender}</td>
              <td>{user.YearofStudy}</td>
            </tr>)
          })}
        </table>
    </div>
  )
}

export default RegistrationDetails
